@import '../../assets/scss/variable.scss';

#loading {
  background-color: $white-color;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  margin-top: 0px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  overflow: hidden !important;
  right: 0px;
  z-index: 999999;
}

#loading-center {
  width: 100%;
  height: 100%;
  position: relative;
}

#loading-center img {
  text-align: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  z-index: 99;
  margin: 0 auto;
}
