/*--------------------------------------------*/
/* Typography Variable */
/*--------------------------------------------*/
$body-font: 'Outfit', sans-serif;
$title-font: 'Outfit', sans-serif;

/*--------------------------------------------*/
/* Color Variable */
/*--------------------------------------------*/
$body-color: #999999;
$primary-color: #0222F1;
// $primary-color: #db2d2e;
$secondary-color: #323232;
$title-color: #363636;
$dark-color: #091c3a;
$black-color: #000000;
$gray-color: #626262;
$gray-2-color: #909090;
$gray-3-color: #808080;
$gray-4-color: #a5a5a5;
$gray-5-color: #cccccc;
$gray-6-color: #eeeeee;
$gray-7-color: #dedede;
$gray-8-color: #eceff8;
$white-color: #ffffff;
$light-color: #fafafa;
$red-color: #ff0000;

/*--------------------------------------------*/
/* Border Color Variable */
/*--------------------------------------------*/
$border-color: #e3e3e3;
$border-light-color: #f5f5f5;
$hr-border-color: #232323;
$hr-gray-border-color: #dedede;

/*--------------------------------------------*/
/* Other Variable */
/*--------------------------------------------*/

$radius-3: 3px;
$radius-5: 5px;
$radius-7: 7px;
$radius-10: 10px;
$radius-12: 12px;
$radius-30: 30px;
$radius-50: 50px;
$radius-80: 80px;
$radius-100: 100%;

$transition: all 0.25s ease-in-out;
$transition-2: all 0.2s ease-in-out;
$transition-3: all 0.3s ease-in-out;
$transition-4: all 0.4s ease-in-out;
$transition-5: all 0.5s ease-in-out;
$transition-6: all 0.6s ease-in-out;
